import { useEffect, useState } from 'react';
import Head from 'next/head';
import {getFreshChatInfo as getFreshChatInfoApi, setFreshChatInfo as setFreshChatInfoApi} from "../../../data/shopify";
import {userReducer} from "../../../types";
import rnaAnalytics from 'services/analytics/rnaAnalytics';
import { AnalyticsEventAction } from 'constants/analytics';
import { sellerStoreId, userOnboardingStatus } from 'utils/auth';

interface Props {
  user?: userReducer;
}

const FreshchatComponent = ({ user }: Props) => {
  const [restoreId, setRestoreId] = useState('');
  const [externalId, setExternalId] = useState(null);

  useEffect(() => {
    function getFreshChatInfo() {
      try {
        if (!sellerStoreId()) return;

        getFreshChatInfoApi().then((res) => {
          setExternalId(res?.externalId);
          setRestoreId(res?.restoreId ? res?.restoreId : '');
        });
      } catch (e) {
        console.log(e);
      }
    }

    if (!userOnboardingStatus()?.length) getFreshChatInfo();

    const setFreshChatInfoInterval = setInterval(async () => {
      try {
        // @ts-ignore
        if (window.restoreIdGivenByFreshChat) {
          // @ts-ignore
          await setFreshChatInfoApi(window.restoreIdGivenByFreshChat);
          clearInterval(setFreshChatInfoInterval);
        }
      } catch (e) {
        console.log(e);
        clearInterval(setFreshChatInfoInterval);
      }
    }, 1000);

    const checkFreshChatWidgetLoaded = setInterval(() => {
      try {
        // @ts-ignore
        if (window.fcWidget) {
          clearInterval(checkFreshChatWidgetLoaded);
          // @ts-ignore
          if (!window.isWidgetOpenedListenerRegistered) {
            // @ts-ignore
            window.fcWidget.on('widget:opened', function() {
              rnaAnalytics.clickEvent({ action: AnalyticsEventAction.HelpIconClicked }).send();
            });
            // @ts-ignore
            window.isWidgetOpenedListenerRegistered = true;
          }
        }
      } catch (e) {
        console.log(e);
        clearInterval(checkFreshChatWidgetLoaded);
      }
    }, 1000);

    return () => {
      clearInterval(setFreshChatInfoInterval);
      clearInterval(checkFreshChatWidgetLoaded);
    };
  }, []);

  return (
    <Head>
      {user?.shopifyStoreData?.name && externalId && (
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.fcWidgetMessengerConfig = {
                externalId: "${externalId}",
                restoreId: '${restoreId}' === '' ? null : '${restoreId}',
              }
              window.fcSettings = {
                onInit: function() {
                  window.fcWidget?.user.get(function(resp) {                  
                    var status = resp && resp.status,
                      data = resp && resp.data;
                    if (status !== 200) {                    
                      window.fcWidget.user.setProperties({
                        firstName:'${user?.shopifyStoreData?.name}',
                        email: '${user?.shopifyStoreData?.emailAddress}',
                        phone: '${user?.shopifyStoreData?.phoneNumber}',
                      });
                      window.fcWidget.on('user:created', function(resp) {                      
                        var status = resp && resp.status,
                          data = resp && resp.data;
                        if (status === 200) {
                          if (data.restoreId) {
                            window.restoreIdGivenByFreshChat = data.restoreId;
                          }
                        }
                      });
                    }
                  });
                }
              }
            `,
          }}
        ></script>
      )}

      {user?.storesFetched && user?.shopifyStoreData?.name && process?.env?.NEXT_PUBLIC_FRESHCHAT_URL && externalId && (
        <script
          src={process.env.NEXT_PUBLIC_FRESHCHAT_URL}
          data-chat='true'
          id='freshchat-js-lib'
          defer
        ></script>
      )}
    </Head>
  );
};

export default FreshchatComponent;
